import "../styles/globals.css";

import { DefaultSeo } from "next-seo";
import Router, { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import type { AppProps } from "next/app";

Router.events.on("routeChangeComplete", url => {
  if (process.env.NODE_ENV === "production") {
    if (window["analytics"] !== undefined) {
      window["analytics"].page(url);
    }

    if (window["_hsq"] !== undefined) {
      window["_hsq"].push(["setPath", url]);
      window["_hsq"].push(["trackPageView"]);
    }
  }
});

function MyApp({ Component, pageProps }: AppProps) {
  const trackedInitialPage = useRef(false);
  const { asPath } = useRouter();

  useEffect(() => {
    if (!trackedInitialPage.current) {
      if (process.env.NODE_ENV === "production" && window["_hsq"] !== undefined) {
        // Set the initial page route for hubspot tracking
        window["_hsq"].push(["setPath", asPath]);
        trackedInitialPage.current = true;
      }
    }
  }, [asPath]);

  return (
    <>
      <DefaultSeo
        titleTemplate="Enerflo | %s"
        title="THE Solar Platform"
        description="We sell solar."
        additionalLinkTags={[
          {
            rel: "icon",
            href: "/favicon.ico",
          },
        ]}
      />
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
        useEnterprise
        scriptProps={{ defer: true }}
      >
        <Component {...pageProps} />
      </GoogleReCaptchaProvider>
    </>
  );
}

export default MyApp;
